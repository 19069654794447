.split__ {
  display: grid;
  margin: 0 auto;
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 450px) {
  .split__ {
    grid-template-columns: 1fr !important;
    gap: 30px !important;

    .column1 {
      justify-content: center !important;
      width: 100%;
    }
    .column2 {
      justify-content: center !important;
      width: 100%;
    }
  }
}
