*{
  padding: 0;
  margin: 0;
  font-family: "Proxima Soft", Arial, Helvetica, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Proxima Soft", Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,li {
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 25px;
  color: #2E3338;
}

h1{
  font-size: 45px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important;
  color: #2E3338;
}

h1,h2,h3,h4,h5,h6{
  color: #2E3338;
}
