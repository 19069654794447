.inner-div_ {
  display: grid;
  justify-items: center;
  margin: 0 auto;
  gap: 30px;
  align-items: baseline;
}

@media screen and (max-width: 850px) {
  .inner-div_ {
    width: 80% !important;
  }
}

@media screen and (max-width: 450px) {
  .inner-div_ {
    width: 90% !important;
  }
}
