@import "../../style.scss";

.three-in-one__ {
  display: grid;
  align-items: baseline;
  gap: 30px;
  h1 {
    font-size: 45px;
  }

  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 64em) {
  .three-in-one__ {
    h1 {
      font-size: 35px !important;
    }
    p {
      text-align: center;
    }
  }
}
@media screen and (max-width: 48em) {
  .three-in-one__ {
    img {
      width: 100%;
    }
    h1 {
      font-size: 30px !important;
    }
    img {
    }
    p {
      font-size: 16px !important;
      text-align: center;
    }
  }
}
